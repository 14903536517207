import * as React from "react"
import Button from '../components/Button.js'

export default function Box({
    title = 'title',
    content = 'Lorem ipsum dolor sit amet',
    toPath = '/',
    toLabel = "Home",
    children,
    icon,
    boxClass = "box z1 p-2r rounded bg-color-white",
    btnClass = "action jc-c c-purple round db p-3 w-100 btn outline",    
    titleClass = "h2 c-purple ms-3 bold",
    hasIcon = false,
    ...others
  }){

  return (
    <div className={boxClass} {...others}>

      <div className="card p-2r rounded bg-color-white d-flex flex-column justify-content-between mb-4 mb-lg-0">
        <Button to={toPath}>
          <div className="packing">
            <div className="title df ai-c mb-4">
              {icon}
              <h4 className={titleClass}>
                {title}
              </h4>
            </div>
            <p className="mb-4 fs-1r c-grey">{content}</p>
          </div>
        </Button>
        <Button to={toPath} className={btnClass} hasIcon>
          {toLabel}
        </Button>
      </div>
      
    </div>
  ) 
}
