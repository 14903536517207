import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../components/Button.js"
import Container from "../helpers/Container.js"
import { useStaticQuery, graphql } from "gatsby"

export default function EmpressaParceiras({
  btnLabel='Ver Cases',
  to='/cases'
}) {
  
  const data = useStaticQuery(graphql`
    query {
      allWpEmpresa {
        nodes {
          empresasFields {
            comunicacaoEmpresasNome
            comunicacaoEmpresasLogo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Container className="pt-4r pb-4r ai-c dfc sep-y">
      <h4 className="h2 c-purple mb-4 s-t">Empresas que confiam em nosso trabalho</h4>
      <div className="container">
        <div className="row justify-content-center limited-size"> 
        
          {
            data.allWpEmpresa.nodes.map((node, index) => (
              <div className="col-6 col-md-4 col-lg-2 d-flex align-items-center justify-content-center py-2" key={`logo-${index}`}>              
                <GatsbyImage 
                  imgClassName="img-fluid"
                  image={getImage(node.empresasFields.comunicacaoEmpresasLogo ? 
                    node.empresasFields.comunicacaoEmpresasLogo.localFile : "../../images/post-image.png")}
                  alt={node.empresasFields.comunicacaoEmpresasLogo ? 
                    node.empresasFields.comunicacaoEmpresasLogo.altText : "Placeholder Image"}                
                  key={`logo-${index}`}
                />
              </div>
            ))
          }
        </div>
      </div>
      <Button to={to} className="btn outline dib h5 mt-4r" hasIcon>
        {btnLabel}
      </Button>
    </Container>
  )
}