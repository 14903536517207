import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { Helmet } from "react-helmet"

import EmpressaParceiras from "../common/section/EmpresasParceiras.js"
import FormContatoImageSection from "../common/section/FormContatoImageSection.js"

import Page from "../common/elements/Page.js"
import Box from "../common/elements/Box.js"

import Button from "../common/components/Button.js"

import Container from "../common/helpers/Container.js"
import ContainerWithBg from "../common/helpers/ContainerWithBg.js"
import Icon from "../common/elements/Icon.js"

let c = 'col-12 col-sm-10 col-md-8 col-lg-4 d-flex';

export default function ConsultoriaPage({ data }) {

  const fields = data.wpPage ? data.wpPage.paginaConsultoriaFields : null

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/consultoria-hero.png"></link>
      </Helmet>
      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-consultoria-hero py-8 py-lg-0"
        className="container-md tac feature-image line-lenght-large ai-c jc-c dfc c-white p-r z1"
      >
        <h1 className="h1-egg mb-15r">Projetos</h1>
        <p className="mb-0">
          Nosso foco está em <strong>Gestão Estratégica de Projetos, Planejamento de Comunicação Integrada</strong> (do Diagnóstico de Comunicação à Mensuração de Resultados) e <strong>Mapeamento de Fluxos de Comunicação</strong>.
        </p>
      </ContainerWithBg>
      <ContainerWithBg classBgName="bg-color-purple-3 mt--2r pb-4r" className="container-xg">
        <div className="container-fluid z1">
          <div className="row justify-content-center">
            {
              data.allWpConsultoria.nodes.map(node => (
                <Box
                  title={node.title}
                  content={node.consultoriaFields.comunicacaoConsultoriaConteudo}
                  toPath={node.link}
                  toLabel="Conheça"
                  btnClass = "bttn outline c-purple h3 jc-c"
                  titleClass="h3 c-purple ml-1r mb-0"
                  hasIcon
                  boxClass={c}
                  icon={<Icon type={node.consultoriaFields.comunicacaoConsultoriaIcone} />}
                  key={node.id}
                />
              ))
            }
          </div>
        </div>
      </ContainerWithBg>
      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-consultoria py-8 px-4 d-flex jc-c"
        className="packing mh-27r line-lenght-medium c-white dfc z1 p-r d-flex ai-c jc-c"
      >
        <h2 className="h2 mb-5 c-golden">Principais diferenciais</h2>
        <p className="mb-4">
          Equipe de consultores seniores:  liderança em projetos de comunicação e empresas de médio e grande porte (incluindo multinacionais);
        </p>
        <p className="mb-4">
          Experiência prática e acadêmica (nosso quadro é composto por mestres e docentes das maiores universidades do país);
        </p>
        <p className="mb-4">
          Produção e atualização contínua (investimos na capacitação com pares e referências nas áreas de gestão, planejamento de comunicação integrada, estratégia e projetos de comunicação).
        </p>
      </ContainerWithBg>
      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 bg-color-purple d-flex p-0">
            <StaticImage src="../images/img-consultoria-contratar.png" alt="Projetos" />
          </div>
          <div className="col-md-6 order-1 order-md-2 bg-color-purple-1 p-0 d-flex ai-c jc-c">
            <div className="col-size-560 tal c-white my-8 mx-4">
              <h4 className="mb-1r h2">{fields.comunicacaoConsultoriaPorque.comunicacaoConsultoriaPorqueTitulo}</h4>
              <div 
                dangerouslySetInnerHTML={{ 
                  __html: fields.comunicacaoConsultoriaPorque.comunicacaoConsultoriaPorqueConteudo 
                }}  
                className="white pb-1r" 
              />

              <Button to="#submit_form_contact" className="bttn c-white outline dib invert h5 jc-c fs-body-default" hasIcon>
                Entre em contato
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <EmpressaParceiras />
      <FormContatoImageSection title={fields.comunicacaoConsultoriaIntro} />
    </Page >
  )
}

export const pageQuery = graphql`
  query {
    allWpConsultoria(sort: { fields: [title] }) {
      nodes {
        id
        title
        link
        consultoriaFields {
          comunicacaoConsultoriaConteudo
          comunicacaoConsultoriaIcone
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 19039}) {
      nodeType
      title
      uri
      paginaConsultoriaFields {
        comunicacaoConsultoriaIntro
        comunicacaoConsultoriaPorque {
          comunicacaoConsultoriaPorqueConteudo
          comunicacaoConsultoriaPorqueTitulo
        }
      }
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
`