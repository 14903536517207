import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FormContato from "../elements/FormContato.js"

export default function FormContatoImageSection({title = null, className=""}) {
  return (
    <div className={`container-fluid ${className}`}>
      <section className="row">
        <div className="col-md-6 order-2 order-md-1 bg-color-purple d-flex p-0">
          <div className="bg-pattern-linear-darker p-r">
            <StaticImage className="h-100" src="../../images/mulher_tablet.png" alt="mulher" />
          </div>
        </div>
        <div className="col-md-6 order-1 order-md-2">
          <FormContato title={title} />
        </div>
      </section>
    </div>
  )
}
