import React, { Component } from "react"
import ReCAPTCHA from "react-google-recaptcha";

import Input from "../components/input.js"
import TextArea from "../components/TextArea.js"

export default class FormContato extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      submitting: false,
      status: null,
      token: '',
      invalidToken: false
    }
  }

  handleServerResponse = (ok, msg, form) => {

    this.setState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  onChange(value) {
    this.setState({token: value, invalidToken: false})
  }

  handleOnSubmit = e => {
    e.preventDefault();

    if(!this.state.token) {
      this.setState({invalidToken: true})
      return
    }

    this.setState({invalidToken: false})

    const form = e.target;
    this.setState({ submitting: true });

    var xhr, data = new FormData();

    if (window.XMLHttpRequest) {
      xhr = new XMLHttpRequest();
    } else {
      // xhr = new ActiveXObject("Microsoft.XMLHTTP");
    }

    var var_nome = document.querySelector("#submit_form_contact #nome");
    var var_empresa = document.querySelector("#submit_form_contact #empresa");
    var var_email = document.querySelector("#submit_form_contact #e-mail");
    var var_telefone = document.querySelector("#submit_form_contact #telefone");
    var var_mensagem = document.querySelector("#submit_form_contact #descreva_seu_interesse");
    var var_interesse = document.querySelector("#submit_form_contact #interesse");
    var var_newsletter = document.querySelector("#submit_form_contact #newsletter");

    data.append("nome", var_nome.value);
    data.append("empresa", var_empresa.value);
    data.append("email", var_email.value);
    data.append("telefone", var_telefone.value);
    data.append("mensagem", var_mensagem.value);
    data.append("interesse", var_interesse.value);
    data.append("newsletter", var_newsletter.checked);

    xhr.onreadystatechange = () =>  {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        if(typeof(window.dataLayer) !== 'undefined') window.dataLayer.push({ 'event': 'form-converted' });
        this.handleServerResponse(true, 'Sua mensagem foi recebida com sucesso. Em breve, entraremos em contato', form);
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        this.setState({ submitting: false });
        this.handleServerResponse(false, 'Erro ao enviar o formulário', form);
      }
    };
    xhr.open("POST", "https://backoffice.comunicacaointegrada.com.br/wp-json/contact-form-7/v1/contact-forms/17630/feedback", true);
    xhr.send(data);
  };

  render() {

    const { submitting, status, invalidToken } = this.state
    const { title } = this.props

    return (
      <div className="mw-31r">
        <p className="c-grey mb-1r mt-4r">
          {title && 
            title
          }
          { !title && 
            "Caso tenha interesse em nossas consultorias, cursos, palestras e treinamentos in company, preencha o formulário abaixo."
          }
        </p>
        <form id="submit_form_contact" className="mb-4r" onSubmit={this.handleOnSubmit} disabled={submitting}>
          <fieldset className="dfc mb-1r">
            <label htmlFor="interesse">Qual seu interesse?</label>
            <select id="interesse" name="interesse" placeholder="Qual seu interesse?" required>
              <option value="Consultoria">Consultoria</option>
              <option value="Workshop">Workshop in Company</option>
              <option value="Curso">Curso</option>
              <option value="Entrevistas">Entrevistas</option>
              <option value="Parceria">Parceria</option>
              <option value="Conteúdo">Conteúdo</option>
              <option value="Outros">Outros</option>
            </select>
          </fieldset>
          <Input label="Nome" placeholder="Seu nome" required />
          <Input label="Empresa" placeholder="Empresa" required />
          <Input label="E-mail" placeholder="E-mail institucional" type="email" required />
          <Input label="Telefone" placeholder="(DD)+Número" required />
          <TextArea label="Descreva seu interesse" placeholder="Sua mensagem" required />
          <div className="form-check mb-1r">
            <input className="form-check-input" type="checkbox" value="" id="newsletter" />
            <label className="form-check-label" htmlFor="newsletter">
              Quero me cadastrar para receber a newsletter e outros conteúdos e novidades da Comunicação Integrada, podendo cancelar a inscrição quando quiser.
            </label>
          </div>
          <div className="mb-1r">
            <ReCAPTCHA
              sitekey="6LdgZbAbAAAAAGEtaZOhaLv7zfXBk_UFwlVmyfJJ"
              onChange={this.onChange.bind(this)}
            />
            { invalidToken && (<p className="fs-1r c-purple">Campo obrigatório!</p>)}
          </div>
          <button type="submit" className={`btn bg-color-purple ${submitting ? "sending" : ""}`}>
            <span className="spinner" role="status" aria-hidden="true"></span>
            <span className="label">Enviar mensagem</span>
          </button>
          {status && (
            <p className={`mt-1r ${!status.ok ? "errorMsg" : ""}`}>
              {status.msg}
            </p>
          )}
        </form>
      </div>
    )
  }
}