import * as React from "react"

export default function TextArea({label, placeholder}) {
  const id = label.toLowerCase().split(" ").join("_")
  return (
    <fieldset className="dfc mb-1r">
      <label htmlFor={id} className="c-grey">{label}</label>
      <textarea id={id} className="input" placeholder={placeholder}></textarea>
    </fieldset>
  )
}